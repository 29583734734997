import { useEffect, useState } from "react"
import { ISlaItem, SlaField, SlaItemType } from "@/shared/types"

import { Box, Copy, Flex } from "@/shared/components"
import { useOrganizationForm } from "@/management/components"

import { SlaRuleItem } from "./SlaRuleItem"
import { AddButton } from "./AddButton"
import { RemoveButton } from "./RemoveButton"
import { GroupSelect } from "./GroupSelect"

const filterNodes = (nodes?: ISlaItem[]) => {
  return nodes?.filter((i) => i.type === SlaItemType.Rule) || []
}

const isGroupContainsConsigneeLinesOnly = (name: SlaField, nodes?: ISlaItem[]) => {
  const filteredNodes = filterNodes(nodes)

  if (filteredNodes.length === 0) return false

  if (!filteredNodes.some((i) => i.field === name)) {
    if (filteredNodes.every((i) => i.field === SlaField.Consignee)) {
      return true
    } else if (filteredNodes[filteredNodes.length - 1].field === SlaField.Consignee) {
      return true
    }
  }

  return false
}

const isGroupHaveConsigneeAfterMainField = (name: SlaField, nodes?: ISlaItem[]) => {
  const filteredNodes = filterNodes(nodes)
  const mainFieldIndex = filteredNodes.findIndex((el) => el.field === name)

  const consigneeLastIndex =
    filteredNodes
      .reduce((a: number[], e, i) => {
        if (e.field === SlaField.Consignee) a.push(i)
        return a
      }, [])
      .pop() || 0

  return mainFieldIndex >= 0 ? consigneeLastIndex > mainFieldIndex : false
}

const isGroupHaveCustomerAfterMainField = (nodes?: ISlaItem[]) => {
  const filteredNodes = filterNodes(nodes)
  const mainFieldIndex = filteredNodes.findIndex((el) => el.field === SlaField.ExcludedCarriers)
  const shipperLastIndex =
    filteredNodes
      .reduce((a: number[], e, i) => {
        if (e.field === SlaField.Shipper) a.push(i)
        return a
      }, [])
      .pop() || 0

  return mainFieldIndex >= 0 ? shipperLastIndex > mainFieldIndex : false
}

const isGroupHaveAddressAfterMainField = (nodes?: ISlaItem[]) => {
  const filteredNodes = filterNodes(nodes)
  const mainFieldIndex = filteredNodes.findIndex((el) => el.field === SlaField.ExcludedCarriers)
  const addressLastField =
    filteredNodes
      .reduce((a: number[], e, i) => {
        if (e.field === SlaField.AddressFrom || e.field === SlaField.AddressTo) a.push(i)
        return a
      }, [])
      .pop() || 0

  return mainFieldIndex >= 0 ? addressLastField > mainFieldIndex : false
}

const isGroupTrackingHaveShipperSingleWithoutMainField = (name: SlaField, nodes?: ISlaItem[]) => {
  if (name !== SlaField.Tracking) return false

  const filteredNodes = filterNodes(nodes)
  if (filteredNodes.length === 0) return false

  if (!filteredNodes.some((i) => i.field === SlaField.Tracking)) {
    if (filteredNodes.every((i) => i.field === SlaField.Shipper)) {
      return true
    } else if (filteredNodes[filteredNodes.length - 1].field === SlaField.Shipper) {
      return true
    }
  }

  return false
}

type ISlaGroupItem = Pick<ISlaItem, "group" | "nodes"> & {
  name: SlaField
  path?: number[]
  isLastChild?: boolean
  isViewMode?: boolean
}

export const SlaGroupItem = ({ name, path = [], group, nodes, isLastChild, isViewMode }: ISlaGroupItem) => {
  const { slaErrors, setSlaErrors } = useOrganizationForm("SlaGroupItem")
  const [errorText, setErrorText] = useState("")

  useEffect(() => {
    if (nodes && !isViewMode) {
      if (nodes.length === 0 && !slaErrors?.includes(`slaGroup_${name}_${path.join(".")}`)) {
        setSlaErrors([...slaErrors, `slaGroup_${name}_${path.join(".")}`])
      }

      if (nodes.length > 0 && slaErrors?.includes(`slaGroup_${name}_${path.join(".")}`)) {
        setSlaErrors(slaErrors.filter((i) => i !== `slaGroup_${name}_${path.join(".")}`))
      }

      if (
        isGroupContainsConsigneeLinesOnly(name, nodes) &&
        !slaErrors?.includes(`slaGroup_consignee_${name}_${path.join(".")}`)
      ) {
        setSlaErrors([...slaErrors, `slaGroup_consignee_${name}_${path.join(".")}`])
      }
      if (
        !isGroupContainsConsigneeLinesOnly(name, nodes) &&
        slaErrors?.includes(`slaGroup_consignee_${name}_${path.join(".")}`)
      ) {
        setSlaErrors(slaErrors.filter((i) => i !== `slaGroup_consignee_${name}_${path.join(".")}`))
      }

      if (
        isGroupHaveConsigneeAfterMainField(name, nodes) &&
        !slaErrors?.includes(`slaGroup_consignee_after_main_field_${name}_${path.join(".")}`)
      ) {
        setSlaErrors([...slaErrors, `slaGroup_consignee_after_main_field_${name}_${path.join(".")}`])
      }
      if (
        !isGroupHaveConsigneeAfterMainField(name, nodes) &&
        slaErrors?.includes(`slaGroup_consignee_after_main_field_${name}_${path.join(".")}`)
      ) {
        setSlaErrors(
          slaErrors.filter((i) => i !== `slaGroup_consignee_after_main_field_${name}_${path.join(".")}`),
        )
      }

      if (
        isGroupHaveCustomerAfterMainField(nodes) &&
        !slaErrors?.includes(`slaGroup_customer_after_main_field_${name}_${path.join(".")}`)
      ) {
        setSlaErrors([...slaErrors, `slaGroup_customer_after_main_field_${name}_${path.join(".")}`])
      }
      if (
        !isGroupHaveCustomerAfterMainField(nodes) &&
        slaErrors?.includes(`slaGroup_customer_after_main_field_${name}_${path.join(".")}`)
      ) {
        setSlaErrors(
          slaErrors.filter((i) => i !== `slaGroup_customer_after_main_field_${name}_${path.join(".")}`),
        )
      }
      if (
        isGroupHaveAddressAfterMainField(nodes) &&
        !slaErrors?.includes(`slaGroup_address_after_main_field_${name}_${path.join(".")}`)
      ) {
        setSlaErrors([...slaErrors, `slaGroup_address_after_main_field_${name}_${path.join(".")}`])
      }
      if (
        !isGroupHaveAddressAfterMainField(nodes) &&
        slaErrors?.includes(`slaGroup_address_after_main_field_${name}_${path.join(".")}`)
      ) {
        setSlaErrors(
          slaErrors.filter((i) => i !== `slaGroup_address_after_main_field_${name}_${path.join(".")}`),
        )
      }
      if (
        isGroupTrackingHaveShipperSingleWithoutMainField(name, nodes) &&
        !slaErrors?.includes(`slaGroup_shipper_single_without_main_field_${name}_${path.join(".")}`)
      ) {
        setSlaErrors([...slaErrors, `slaGroup_shipper_single_without_main_field_${name}_${path.join(".")}`])
      }
      if (
        !isGroupTrackingHaveShipperSingleWithoutMainField(name, nodes) &&
        slaErrors?.includes(`slaGroup_shipper_single_without_main_field_${name}_${path.join(".")}`)
      ) {
        setSlaErrors(
          slaErrors.filter(
            (i) => i !== `slaGroup_shipper_single_without_main_field_${name}_${path.join(".")}`,
          ),
        )
      }
    }
  })

  useEffect(() => {
    if (slaErrors?.includes(`slaGroup_consignee_${name}_${path.join(".")}`)) {
      setErrorText("Consignee field could not be used without main field")
    } else if (slaErrors?.includes(`slaGroup_consignee_after_main_field_${name}_${path.join(".")}`)) {
      setErrorText("Consignee field could not go after main field")
    } else if (slaErrors?.includes(`slaGroup_address_after_main_field_${name}_${path.join(".")}`)) {
      setErrorText("Address field could not go after main field")
    } else if (slaErrors?.includes(`slaGroup_customer_after_main_field_${name}_${path.join(".")}`)) {
      setErrorText("Customer field could not go after main field")
    } else if (slaErrors?.includes(`slaGroup_shipper_single_without_main_field_${name}_${path.join(".")}`)) {
      setErrorText("Customer field could not be used without main field")
    } else if (slaErrors?.includes(`slaGroup_${name}_${path.join(".")}`)) {
      setErrorText("Group could not be empty")
    } else setErrorText("")
  }, [errorText, setErrorText, slaErrors, name, path])

  if (!nodes) {
    return null
  }

  return (
    <Box
      css={{
        padding: "$24 $16",
        marginLeft: path.length === 0 ? 0 : "$80",
        marginTop: path.length === 0 ? 0 : "$32",
        backgroundColor: path.length % 2 === 0 ? "$system-white" : "$theme-n2-n7",
        borderRadius: "$8",
        position: "relative",

        before: {
          content: path.length === 0 ? "unset" : "''",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          left: "-$32",
          width: "$32",
          height: "$2",
          backgroundColor: "$theme-n3-n7",
        },

        after: {
          content: path.length === 0 ? "unset" : "''",
          position: "absolute",
          top: "-$32",
          left: "-$32",
          width: "$2",
          height: `calc(${isLastChild ? "50%" : "100%"} + $32)`,
          backgroundColor: "$theme-n3-n7",
          zIndex: "$1",
        },
      }}
    >
      <Flex align="center" justify="between">
        <GroupSelect name={name} path={path} group={group} isViewMode={isViewMode} />
        {!isViewMode ? (
          <Flex css={{ gap: "$12" }}>
            <AddButton
              type={SlaItemType.Rule}
              name={name}
              path={[...path, nodes.length]}
              disabled={nodes.length >= 100}
            />
            <AddButton
              type={SlaItemType.Group}
              name={name}
              path={[...path, nodes.length]}
              disabled={nodes.length >= 100}
            />
            <RemoveButton type={SlaItemType.Group} name={name} path={path} />
          </Flex>
        ) : null}
      </Flex>
      {nodes.map((i, index) => {
        return i.type === SlaItemType.Rule ? (
          <SlaRuleItem
            key={index}
            name={name}
            path={[...path, index]}
            field={i.field}
            op={i.op}
            values={i.values}
            isLastChild={nodes.length === index + 1}
            isViewMode={isViewMode}
          />
        ) : (
          <SlaGroupItem
            key={index}
            name={name}
            path={[...path, index]}
            group={i.group}
            nodes={i.nodes}
            isLastChild={nodes.length === index + 1}
            isViewMode={isViewMode}
          />
        )
      })}
      {errorText ? (
        <Copy color="special-error" css={{ marginTop: "$16" }}>
          {errorText}
        </Copy>
      ) : null}
    </Box>
  )
}
