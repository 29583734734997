import { useState } from "react"
import { useFormContext } from "react-hook-form"

import { CarrierSourceType, IOrganizationState } from "@/shared/types"
import { lengthRule } from "@/shared/rules"
import { CONTACTS_MAX } from "@/constants"
import { getCarriersNextContactIdFn } from "@/api/slaApi"

import {
  AddItemButton,
  Box,
  Copy,
  EmailController,
  Flex,
  Grid,
  GridItem,
  Redacted,
  Spacer,
  TextFieldController,
  Title,
} from "@/shared/components"

import { ContactsTableRow } from "./ContactsTableRow"
import { ContactsTablePlaceholder } from "./ContactsTablePlaceholder"

export const Contacts = ({ isHighway }: { isHighway?: boolean }) => {
  const {
    control,
    register,
    formState: { errors },
    watch,
    setValue,
    trigger,
  } = useFormContext<IOrganizationState>()
  const { newCarrier, name } = watch()
  const { contacts, name: newCarrierName } = newCarrier
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Box css={{ height: 420 }}>
      {isLoading ? (
        <Redacted height="$32" width="170px" text animated />
      ) : (
        <AddItemButton
          text="Add contact"
          onClick={async () => {
            setIsLoading(true)
            const nextCarrierId = await getCarriersNextContactIdFn()
            if (nextCarrierId) {
              setIsLoading(false)
            }
            setValue(
              "newCarrier.contacts",
              [
                {
                  id: nextCarrierId,
                  name: "",
                  role: "",
                  phone: "",
                  email: "",
                  tenderContact: true,
                  sourceType: CarrierSourceType.APOLLO,
                },
                ...contacts,
              ],
              { shouldDirty: true },
            )
            setTimeout(() => {
              trigger("newCarrier.contacts")
            }, 0)
          }}
          disabled={contacts.length >= CONTACTS_MAX}
          css={{ width: "fit-content" }}
        />
      )}
      <Spacer size={24} />
      {isLoading ? (
        <ContactsTablePlaceholder />
      ) : (
        <Box>
          <ContactsTableRow
            name="Name*"
            role="Role"
            phone="Phone"
            email="Email*"
            tenderContact="Tender & Bid contact"
            isTableHead
          />
          {contacts.length > 0 ? (
            contacts.map(({ id, name, role, phone, email, sourceType }, index) => (
              <ContactsTableRow key={index} {...{ index, id, name, role, phone, email, sourceType }} />
            ))
          ) : (
            <Flex
              align="center"
              justify="center"
              css={{
                height: "$64",
                color: "$theme-b-n3",
              }}
            >
              <Copy color="theme-n6-n5">There is no data yet</Copy>
            </Flex>
          )}
          <Spacer size={32} />
          <Title as="h2" scale={6} color="theme-b-n3">
            {isHighway ? name : newCarrierName} Carrier Contact
          </Title>
          <Spacer size={32} />
          <Grid columns="1fr 1fr 1fr 1fr" columnGap={16}>
            <GridItem>
              <TextFieldController<IOrganizationState>
                name="newCarrier.carrierRepName"
                control={control}
                register={register}
                trigger={trigger}
                description="Name"
                rules={{ ...lengthRule("Value", 40, 2) }}
                error={errors.newCarrier?.carrierRepName?.message}
              />
            </GridItem>
            <GridItem>
              <EmailController<IOrganizationState>
                name="newCarrier.carrierRepEmail"
                control={control}
                register={register}
                trigger={trigger}
                description="Email address"
                error={errors.newCarrier?.carrierRepEmail?.message}
              />
            </GridItem>
          </Grid>
        </Box>
      )}
    </Box>
  )
}
